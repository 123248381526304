// Sidebar.js
import React from "react";
import { Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { IoNewspaper } from "react-icons/io5";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="sidebar-content">
      <div
        className={`${
          pathname.includes("reports") ? "sidebar-btn-selected" : "sidebar-btn"
        } px-2`}
        onClick={() => navigate("/reports")}
      >
        <IoNewspaper className="me-2 fs-4" /> Sales & Reports
      </div>
    </div>
  );
};

export default Sidebar;
