// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import ReportsPage from "./components/ReportsPage";
import DashboardLayout from "./components/DashboardLayout";
import ReportDetails from "./components/ReportDetails";
import "./App.css";

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUser(user);
    }
  }, []);

  return (
    <BrowserRouter>
      {user ? (
        <DashboardLayout user={user}>
          <Routes>
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/reports/orders/:id" element={<ReportDetails />} />
            <Route path="/*" element={<Navigate replace to="/reports" />} />
          </Routes>
        </DashboardLayout>
      ) : (
        <Routes>
          <Route path="/login" exact element={<LoginPage />} />
          <Route path="/*" exact element={<Navigate replace to="/login" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default App;
