import React, {useState} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {FaArrowRotateRight} from "react-icons/fa6";
import {OtherAPI} from "../axios";

const RetryButton = ({id,key,refetch,status}) => {
    const [retryLoading, setRetryLoading] = useState(false);

    const handleRetryBatch = () => {
        setRetryLoading(true);
        let obj = JSON.stringify({
            batchId: id,
            manageByCron: false,
        });
        OtherAPI.retryBatch(obj)
            .then((res) => {
                console.log(res);
                setRetryLoading(false);
                refetch();
            })
            .catch((err) => {
                console.log(err);
                setRetryLoading(false);
            });
    };
    return (
        <Button
            key={key}
            onClick={handleRetryBatch}
            variant="dark"
            className="text-light"
            disabled={retryLoading || status == "Completed"}
        >
            {retryLoading ? (
                <Spinner size="small" className="text-light" />
            ) : (
                <>
                    <FaArrowRotateRight className="me-1" /> Retry
                </>
            )}
        </Button>
    );
};

export default RetryButton;