import axios from "./config";

class Routes {
  async getBatches() {
    const response = await axios.get(`/sales/getBatches`);
    // error case
    if (response && response.response?.data) return response.response.data;
    // success case
    return response.data;
  }
  async retryBatch(data) {
    const response = await axios.post(`/sales/addNewSales`, data);
    // error case
    if (response && response.response?.data) return response.response.data;
    // success case
    return response.data;
  }

  async getAllSales(id) {
    const response = await axios.get(`/sales/getSales?batchId=${id}`);
    // error case
    if (response && response.response?.data) return response.response.data;
    // success case
    return response.data;
  }
}

export default new Routes();
