import React, { useEffect, useState } from "react";
import { Table, Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./ReportDetails.css";
import { OtherAPI } from "../axios";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const ReportDetails = () => {
  const { id } = useParams();
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [allOrders, setAllOrders] = useState([]);

  const getAllSales = () => {
    if (id) {
      OtherAPI.getAllSales(id)
        .then((res) => {
          console.log(res);
          setAllOrders(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (id) {
      getAllSales();
    }
  }, [id]);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  // Function to handle row click
  const handleRowClick = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  return (
    <div>
      <h2 className="my-3">Report Detail</h2>
      <div className="table table-responsive">
        <Table striped hover>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Order Sequence Number</th>
              <th>Net Sales</th>
              <th>Total Items</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {allOrders.map((order) => (
              <>
                <tr
                    key={order.orderId}
                    onClick={() => {
                      if (order.items.length > 0) {
                        handleRowClick(order.orderId);
                      }
                    }}
                    style={{cursor: "pointer"}}
                >
                  <td>{order.orderId}</td>
                  <td>{order.orderSequenceNumber}</td>
                  <td>{order.netSales}</td>
                  <td>{order.items.length} </td>
                  <td>
                    {order.items.length > 0 && (
                        <>
                          {expandedOrderId == order?.orderId ? (
                              <TiArrowSortedUp/>
                          ) : (
                              <TiArrowSortedDown/>
                          )}
                        </>
                    )}
                  </td>
                </tr>
                {expandedOrderId === order.orderId && (
                  <tr key={`${order.orderId}-details`} className="details-row">
                    <td colSpan="6">
                      <Card className={"bg-dark"}>
                        <Card.Header className={"text-light"}>
                          Items Detail
                        </Card.Header>
                        <Card.Body>
                          <Table striped hover>
                            <thead>
                              <tr>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order?.items?.length > 0 &&
                                order.items.map((item) => (
                                  <tr key={item.itemId}>
                                    <td>{item.itemName}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.total}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ReportDetails;
