// DashboardLayout.js
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Navbar, Dropdown } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar";
import Header from "./Header"; // Import the Header component
import "./DashboardLayout.css"; // Import layout styles

const DashboardLayout = ({ children, user }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 767) {
        setSidebarOpen(false);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <div
          className={`toggle-button d-md-block d-none`}
          style={{
            display: !sidebarOpen ? "block" : "none",
          }}
        >
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              color: "#212529",
              padding: "0.7rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={toggleSidebar}
          >
            <FaAngleDoubleRight />
          </Button>
        </div>
        <div className="header-content">
          <Header user={user} />
        </div>
      </Navbar>
      <Container fluid className="main-container">
        <div className="d-flex flex-row w-100">
          <div className={`sidebar px-2 ${sidebarOpen ? "open" : ""}`}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                paddingTop: "1rem",
              }}
            >
              <p className="fs-5 fw-bold">Sales Dashboard</p>
              <Button
                style={{
                  backgroundColor: "#212529",
                  border: "none",
                  color: "white",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={toggleSidebar}
              >
                <FaAngleDoubleLeft className="text-light" />
              </Button>
            </div>
            <Sidebar />
          </div>
          <div className={`content w-100 ${sidebarOpen ? "open" : ""}`}>
            {children}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DashboardLayout;
