import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { OtherAPI } from "../axios";
import RetryButton from "./RetryButton";

const ReportsPage = () => {
  const navigate = useNavigate();
  const [allBatches, setAllBatches] = useState([]);
  const [retryLoading, setRetryLoading] = useState(false);

  const getAllBatches = () => {
    OtherAPI.getBatches()
      .then((res) => {
        setAllBatches(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllBatches();
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  return (
    <div>
      <h2 className="my-3">Reports & Sales </h2>
      <div className="table-responsive">
        <Table striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date / Time</th>
              <th>Status</th>
              <th>Total Sale</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allBatches.map((batch, index) => (
              <tr key={batch.batchId}>
                <td>{index + 1}</td>
                <td>{formatDate(batch.createdAt)}</td>
                <td>{batch.status}</td>
                <td>{batch.totalSales}</td>
                <td>
                  <RetryButton
                    status={batch.status}
                    id={batch.batchId}
                    refetch={getAllBatches}
                  />
                  <Button
                    onClick={() => navigate(`/reports/orders/${batch.batchId}`)}
                    variant="info"
                    className="text-light ms-2"
                  >
                    <FaEye className="me-1" /> Detail
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ReportsPage;
