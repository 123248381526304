// LoginPage.js
import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Alert,
} from "react-bootstrap";
import { AuthAPI } from "../axios";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(""); // State variable to store error message

  const handleLogin = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (email && password) {
      AuthAPI.login(JSON.stringify({ email: email, password: password }))
        .then((res) => {
          setIsLoading(false);
          if(!res.error){
            localStorage.setItem("user", JSON.stringify(res.user));
            window.location.replace("/reports");}
          else{
            console.log("error===>",res.error);
            setError("Invalid email or password"); // Update error message
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="bg-dark" style={{ height: "100vh", width: "100vw" }}>
      <Container
        style={{
          height: "100%",
        }}
      >
        <Row className="d-flex justify-content-center align-items-center w-100 h-100">
          <Col md={7} xs={12}>
            <Card className="p-4">
              <div className="d-flex justify-content-center">
                <h1>Login Page</h1>
              </div>
              {error && ( // Render error message if error state is not empty
                  <Alert variant="danger" className="mt-3">
                    {error}
                  </Alert>
              )}
              <Form onSubmit={handleLogin} className="mt-5">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter email"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mt-4">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Group>

                <div className="d-flex justify-content-end">
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    className="mt-3"
                    type="submit"
                  >
                    {isLoading ? (
                      <Spinner size="small" className="text-light" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
