// Header.js
import React from "react";
import { Navbar, Nav, Image, Dropdown } from "react-bootstrap";
import { FaBars, FaSignOutAlt } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import { UserIcon } from "../assets";
import { IoNewspaper } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Header = ({ user }) => {
  const navigate = useNavigate();
  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="d-flex justify-content-md-end justify-content-between"
    >
      <div className="d-md-none d-flex ms-2">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            Menu
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate("/reports")}>
              <div className={"fs-6"}>
                <IoNewspaper className="fs-5" /> Sales & Reports
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Dropdown drop="down-centered" className="me-2">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          <Image
            src={UserIcon}
            roundedCircle
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
          />
          Admin
        </Dropdown.Toggle>
        <Dropdown.Menu className="me-3">
          {/* <Dropdown.Item className="px-2">
            <CiSettings className="me-2 ms-1 text-info fs-5" />
            Setting
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={() => {
              localStorage.clear();
              window.location.replace("/login");
            }}
            className="text-danger"
          >
            <FaSignOutAlt className="me-2" />
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  );
};

export default Header;
